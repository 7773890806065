var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { title: "等待支付", footer: null, width: 300 },
          on: { ok: _vm.handleClose },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "margin-bottom": "20px",
                "text-align": "center",
              },
            },
            [
              _vm.apiRes.payDataType == "codeImgUrl"
                ? _c("img", { attrs: { src: _vm.apiRes.payData, alt: "" } })
                : _vm.apiRes.payDataType == "payurl"
                ? _c(
                    "span",
                    [
                      _vm._v("等待用户支付 "),
                      _c("hr"),
                      _vm._v(" 如浏览器未正确跳转请点击： "),
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.apiRes.payData, target: "_blank" },
                        },
                        [_vm._v("支付地址")]
                      ),
                      _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.apiRes.payData,
                              expression: "apiRes.payData",
                              arg: "copy",
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.onCopy,
                              expression: "onCopy",
                              arg: "success",
                            },
                          ],
                          staticClass: "copy-btn",
                          attrs: { size: "small" },
                        },
                        [_vm._v("复制链接")]
                      ),
                    ],
                    1
                  )
                : _c("span", [_vm._v("等待用户支付,请稍后")]),
            ]
          ),
          _c("p", { staticClass: "describe" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.wxApp,
                  expression: "wxApp",
                },
              ],
              attrs: {
                src: require("@/assets/payTestImg/wx_app.svg"),
                alt: "",
              },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.aliApp,
                  expression: "aliApp",
                },
              ],
              attrs: {
                src: require("@/assets/payTestImg/ali_app.svg"),
                alt: "",
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.payText))]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "提示信息",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { id: "successbox" } }, [
            _c("div", { staticClass: "infotip" }, [
              _vm._v(" 感谢您订购智能图片处理服务，您的订单已"),
              _c("span", { staticStyle: { color: "#67c23a" } }, [
                _vm._v("支付成功！"),
              ]),
              _vm._v(
                " 您的智能图片处理服务专属链接为: " +
                  _vm._s(_vm.sealurl) +
                  "，请妥善保存，此链接已同步发送至下单时填写的电子邮箱中。 "
              ),
            ]),
            _c("div", [
              _vm._v(" 本服务一经出售，"),
              _c("span", { staticStyle: { color: "red" } }, [
                _vm._v("不接受退款申请"),
              ]),
            ]),
            _c("div", [
              _c("div", { staticClass: "infotip" }, [
                _vm._v(" 本专属链接只能处理"),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("3")]),
                _vm._v("次图片，处理"),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("3")]),
                _vm._v("次图片后，本链接"),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("自动失效"),
                ]),
                _vm._v("。 "),
              ]),
              _c("div", { staticClass: "infotip" }, [
                _vm._v(" 如果您需立即处理图片，可"),
                _c(
                  "span",
                  {
                    staticStyle: { color: "#1a53ff", cursor: "pointer" },
                    on: { click: _vm.openyw },
                  },
                  [_vm._v("点击此处")]
                ),
                _vm._v("直接跳转到智能图片处理系统。 "),
              ]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.closew } },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }